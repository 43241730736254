
import Vue from 'vue';
import VueComponent from 'vue';
import { AlertModel } from '@/store/models/DialogModel';
import { Component, Watch, Prop } from 'vue-property-decorator';
import {
  Customer,
  CustomerOptions,
  FramePricingOptionTypes
} from '@/store/models/CustomerModel';
import { AdminService } from '@/services/admin-service';
import { NewUserModel, NewCustomerModel } from '@/store/models/ProfileModel';
import AuthService from '@/services/auth-service';
import { SetSnackBar } from '@/helpers/SnackbarHelper';

@Component({
  components: {}
})
export default class AddCustomerDialog extends Vue {
  $refs!: {};
  shouldShowDialog: boolean = false;
  shouldShowDuplicateCustomerNameAlert: boolean = false;
  shouldSendConfirmationEmail: boolean = false;
  shouldSendUserNamePasswordEmail: boolean = false;
  shouldSendTransparencyEmail: boolean = false;
  shouldAddPantonesToItem: boolean = false;
  panelOpenIndex: number = -1;
  valid: boolean = true;
  newPassword: string = '';
  passwordVerify: string = '';
  customer: Customer = new Customer();
  options: CustomerOptions = new CustomerOptions();
  usernameErrorMessages: string[] = [];
  companyNameErrorMessages: string[] = [];
  newCustomerModel: NewCustomerModel = new NewCustomerModel();
  isCreatingNewCustomer: boolean = false;
  isTransparency: boolean = false;
  termsValue: number = 6;
  terms: Array<{
    Terms: string;
    Days: number;
    Id: number;
  }> = [
    {
      Terms: 'Due Upon Receipt',
      Days: 0,
      Id: 5
    },
    {
      Terms: 'Net 15',
      Days: 15,
      Id: 6
    },
    {
      Terms: 'Net 30',
      Days: 30,
      Id: 7
    },
    {
      Terms: 'Net 45',
      Days: 45,
      Id: 8
    }
  ];
  pricingOptions: Array<{
    FrameOption: FramePricingOptionTypes;
    Description: string;
  }> = [
    {
      FrameOption: FramePricingOptionTypes.FramePricingOnly,
      Description: 'Frame Pricing Only'
    },
    {
      FrameOption: FramePricingOptionTypes.QuantityPricingOnly,
      Description: 'Quantity Pricing Only'
    },
    {
      FrameOption: FramePricingOptionTypes.BestOfBoth,
      Description: 'Best of Both World'
    }
  ];
  get passwordConfirmationRule() {
    return (
      this.newCustomerModel.User.Password === this.passwordVerify ||
      'Passwords do not match'
    );
  }
  openDialog() {
    this.shouldShowDialog = true;
  }
  closeDialog() {
    this.refreshDialog();
    this.shouldShowDialog = false;
  }
  async checkForDuplicateCustomerName() {
    const { data } = await AuthService.doesCompanyNameExist(
      this.newCustomerModel.User.CompanyName
    );
    if (data) this.companyNameErrorMessages.push('CompanyName already exists');
    else this.companyNameErrorMessages = [];
  }
  async checkIfUsernameExists() {
    const { data } = await AuthService.doesUserExist(this.newCustomerModel.User.UserName);
    if (data) this.usernameErrorMessages.push('Username already exists');
    else this.usernameErrorMessages = [];
  }
  refreshDialog() {
    this.newCustomerModel = new NewCustomerModel();
  }
  async saveCustomer() {
    this.isCreatingNewCustomer = true;
    let hasFailed = false;
    try {
      const checkForExistingAccountReturn = await AuthService.hasAnIdentityAccount(
        this.newCustomerModel.User.UserName
      );
      if (checkForExistingAccountReturn.data) {
        SetSnackBar(
          'This username/email has already been used, CANNOT SAVE USER / CUSTOMER (Loser).'
        );
        hasFailed = true;
      } else {
        let formData = new FormData();
        formData.append('Email', this.newCustomerModel.User.UserName);
        formData.append('Password', this.newCustomerModel.User.Password);
        const createUserIdentityReturn = await AuthService.createUserForAdmin(formData);
      }
      if (!hasFailed) {
        await AdminService.createCustomer(this.newCustomerModel);
        SetSnackBar('Customer created successfully');
        this.$emit('customerCreated');
      } else {
        SetSnackBar('Error creating customer, ITS not the developers fault its YOURS!');
      }
    } catch (error) {
      SetSnackBar('Error creating customer, ITS not the developers fault its YOURS!');
    }
    this.closeDialog();
    this.refreshDialog();
    this.isCreatingNewCustomer = false;
  }
  async mounted() {}
  /* Created */
}
